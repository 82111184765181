export function addClass(el: HTMLElement, ...className: string[]) {
    if (!el) return;

    el.classList.add(...className)
}

export function addClassWithReflow(el: HTMLElement, ...className: string[]) {
    if (!el) return;

    void el.offsetWidth; // trigger reflow

    el.classList.add(...className)
}

export function deleteClass(el: HTMLElement, ...className: string[]) {
    if (!el) return;
    el.classList.remove(...className)
}

export function deleteClassWithReflow(el: HTMLElement, ...className: string[]) {
    if (!el) return;
    void el.offsetWidth; // trigger reflow
    el.classList.remove(...className)
}

export function replaceClass(el: HTMLElement, srcClass: string, targetClass: string) {
    if (!el) return;

    if (srcClass)
        el.classList.remove(srcClass)

    if (targetClass)
        el.classList.add(targetClass)
}

export function hasClass(el: HTMLElement, className: string): boolean {
    if (!el) return false;
    return el.classList.contains(className)
}